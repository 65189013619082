import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import {
    SESInputOutput,
    ToolsComponent,
    ReassignLoanDealer,
    ResetTestTransactiion,
    RepullDataDump,
    PullDelinquencies,
    PullDelinquenciesComment,
    RefreshDealerMaterializedView,
    PullAllNLSComments,
    NLSDownloadXML,
    SendMailSoarTestEmails,
    DownloadPaymentWithoutVisionIdCsv
} from '../../components';

const styles = makeStyles((theme) => ({
    root: {},
    summaryContainer: {
        paddingLeft: '0px',
        paddingRight: '0px',
        width: '90vw',
    },
    summaryHeader: {
        textAlign: 'left',
        fontSize: '20pt'
    },
}));

const Tools = (props) => {
    const classes = styles();
    return(
        <Fragment>
            <h3 className={classes.summaryHeader}>Tools Page</h3>
            <Grid container direction="column">
                <ToolsComponent toolName = {'Send Email to Admin'} description = {'This will send an email to admin'} InputOutput = {<SESInputOutput/>} />
                <ToolsComponent toolName = {'Pull Delinquencies'} description = {`Click the button to pull today's delinquency record`} InputOutput = {<PullDelinquencies/>} />
                <ToolsComponent toolName = {'Pull Delinquency Comments'} description = {`Click the button to pull today's delinquency comments`} InputOutput = {<PullDelinquenciesComment/>} />
                <ToolsComponent toolName = {'Repull Data Dump'} description = {`It will repull data dump when the button is clicked`} InputOutput = {<RepullDataDump/>} />
                <ToolsComponent toolName = {'Reassign Loan'} description = {`Reassign Loan by Dealer ID`} InputOutput = {<ReassignLoanDealer/>} />
                <ToolsComponent toolName = {'Reset Test Transaction for Current Date'} description = {`Click the button to reset/undo test transactions that are sent today`} InputOutput = {<ResetTestTransactiion/>} />
                <ToolsComponent toolName = {'Refresh Dealer Metric View'} description = {`Click the button to refresh dealer metric`} InputOutput = {<RefreshDealerMaterializedView/>} />
                <ToolsComponent toolName = {'Download NLS Release Hold'} description = {`Click button to download the nightly NLS XML`} InputOutput = {<NLSDownloadXML/>} />
                <ToolsComponent toolName = {'Pull All NLS Comments'} description = {`Click the button to pull All NLS comments`} InputOutput = {<PullAllNLSComments/>} />
                <ToolsComponent toolName = {'Send MailSoar Test Emails'} description = {`Click the button to send test emails to MailSoar's recipients`} InputOutput = {<SendMailSoarTestEmails/>} />
                <ToolsComponent toolName = {'Download a CSV of Payments without a Vision payment id'} description = {`Click the button to download a CSV of payments without a Vision payment id`} InputOutput = {<DownloadPaymentWithoutVisionIdCsv/>} />
            </Grid>
        </Fragment>
    )
}

export default Tools;
